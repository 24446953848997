import axios from "axios";
import qs from "querystring";

export default async (method, url, data = null) => {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  if (sessionStorage["invoice_token"])
    headers["Invoice-Token"] = sessionStorage["invoice_token"];
  const config = {
    method,
    url,
    headers,
    data: data && qs.stringify(data),
  };

  try {
    const res = await axios(config);
    if (res.data && res.data.success && res.data.data) return res.data.data;
    else throw new Error("An error occurred.");
  } catch (err) {
    console.error(err);
    return { error: err.message };
  }
};
