import { createStore } from "redux";
import reducer from "./reducer";
const currentDate = new Date();
const initialState = {
  newCard: {
    brand: "",
    first_name: "",
    last_name: "",
    number: "",
    exp_month: currentDate.getMonth(),
    exp_year: currentDate.getFullYear(),
    cvv: "",
  },
  fileChoice: "cc-new",
  cvv: "",
  invoice: null,
};
const store = createStore(reducer, initialState);
export default store;
