import { SET_FILE_CHOICE, SET_NEW_CARD, SET_CVV, SET_INVOICE } from "./types";

export default (state, action) => {
  switch (action.type) {
    case SET_FILE_CHOICE:
      return {
        ...state,
        fileChoice: action.payload,
      };
    case SET_NEW_CARD:
      return {
        ...state,
        newCard: action.payload,
      };
    case SET_CVV:
      return {
        ...state,
        cvv: action.payload,
      };
    case SET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };
    default:
      return state;
  }
};
