import { SET_FILE_CHOICE, SET_NEW_CARD, SET_CVV, SET_INVOICE } from "./types";

export const setInvoice = (invoice) => {
  return {
    type: SET_INVOICE,
    payload: invoice,
  };
};

export const setFileChoice = (choice) => {
  return {
    type: SET_FILE_CHOICE,
    payload: choice,
  };
};

export const setNewCard = (card) => {
  return {
    type: SET_NEW_CARD,
    payload: card,
  };
};

export const setCvv = (cvv) => {
  return {
    type: SET_CVV,
    payload: cvv,
  };
};
