import callApi from "./callApi";

/**
 * Pays an invoice using ConvergePay's API
 * @param {object} invoice - The invoice to pay.
 * @param {object} [card] - The card data
 * @param {function} onApproval - Function called if payment is approved
 * @param {function} onDeclined - Function called if payment is delined
 * @param {function} onError - Function called if there is an error
 */
export const payWithNewCard = async (
    invoice,
    card,
    onApproval,
    onDeclined,
    onError
) => {
    let exp_date = card.exp_month.toString();
    if (exp_date.length === 1) exp_date = "0" + exp_date;
    exp_date += card.exp_year.toString().substring(2);

    const callback = { onError, onDeclined, onApproval };

    const paymentData = {
        brand: card.brand,
        number: card.number,
        exp_date: exp_date,
        first_name: card.first_name,
        last_name: card.last_name,
        cvv: card.cvv,
    };
    const response = await callApi(
        "put",
        process.env.REACT_APP_API_ENDPOINT + '/terminal/v1/invoices/pay',
        paymentData
    );

    if (response.error || !response.invoice || response.invoice.transaction_response_message !== "APPROVAL" ) {
        return callback.onError(response.error);
    } else {
        return callback.onApproval(response.invoice);
    }

};

/**
 * Pays an invoice using ConvergePay's API
 * @param {object} invoice - The invoice to pay.
 * @param {object} [card_choice] - The card to pay with. ('cc-contact' or 'cc-customer')
 * @param {function} onApproval - Function called if payment is approved
 * @param {function} onDeclined - Function called if payment is delined
 * @param {function} onError - Function called if there is an error
 */
export const payWithCardOnFile = async (
    invoice,
    cardChoice,
    cvv,
    onApproval,
    onDeclined,
    onError
) => {
    const paymentData = {
        type: (cardChoice === "cc-contact" ? 'contact' : 'customer'),
        cvv: (cvv ? cvv : null)
    };
    const callback = { onError, onDeclined, onApproval };

    const response = await callApi(
        "put",
        process.env.REACT_APP_API_ENDPOINT + '/terminal/v1/invoices/pay/card-on-file',
        paymentData
    );

    if (response.error || !response.invoice || response.invoice.transaction_response_message !== "APPROVAL" ) {
        return callback.onError(response.error);
    } else {
        return callback.onApproval(response.invoice);
    }
};
