import React, { useEffect } from "react";
import "./newCreditCardForm.css";
import {
  FaCheck,
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCreditCard,
  FaUser,
  FaCalendar,
  FaLock,
} from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Dropdown, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { setNewCard } from "../../redux";

const NewCreditCardForm = ({ newCard, setNewCard }) => {
  const currentDate = new Date();

  const validate = (state) => {
    const {
      brand,
      first_name,
      last_name,
      number,
      exp_month,
      exp_year,
      cvv,
    } = state;
    let error = null;
    if (brand.length === 0) error = "Card type is a required field.";
    else if (first_name.length === 0) error = "First name cannot be empty.";
    else if (last_name.length === 0) error = "Last name cannot be empty.";
    else if (number.length !== 16) error = "Card number must be 16 digits.";
    else if (exp_month.length === 0)
      error = "Expiry month is a required field.";
    else if (isNaN(exp_month)) error = "Expiry month must be a number.";
    else if (parseInt(exp_month) > 12) error = "Expiry month is not valid.";
    else if (isNaN(exp_year)) error = "Expiry year must be a number.";
    else if (parseInt(exp_year) < currentDate.getFullYear())
      error = "Expiry year is not valid.";
    else if (
      parseInt(exp_year) === currentDate.getFullYear() &&
      parseInt(exp_month) < currentDate.getMonth()
    )
      error = "Expiry date cannot be in the past.";
    else if (cvv.length !== 3) error = "CVV must be a 3 digit number.";

    return error;
  };

  const onChange = (e) => {
    const newState = {
      ...newCard,
      [e.target.name]: e.target.value,
    };
    setNewCard({ ...newState, error: validate(newState) });
  };

  const onNumberChange = (e) => {
    const newState = {
      ...newCard,
      [e.target.name]: e.target.value.replace(/\D/, ""),
    };
    setNewCard({ ...newState, error: validate(newState) });
  };

  const onBrandChange = (value) => {
    const newState = {
      ...newCard,
      brand: value,
    };
    setNewCard({ ...newState, error: validate(newState) });
  };

  useEffect(() => {
    setNewCard({ ...newCard, error: validate(newCard) });
    //eslint-disable-next-line
  }, []);

  if (!newCard) {
    console.log("?");
    return null;
  }
  return (
    <form className='credit-card-mock form-group'>
      <div className='form-check'>
        <label className='form-check-label align-self-center' htmlFor='cc-visa'>
          <FaCreditCard /> Card Type
        </label>
        <Dropdown className='cc-input form-check-input d-inline-block align-self-center'>
          <Dropdown.Toggle
            size='sm'
            variant='outline-secondary'
            id='brand'
            className='align-self-center w-100 text-uppercase'
            name='brand'
          >
            {newCard.brand || "Select Card Type"}
          </Dropdown.Toggle>

          <Dropdown.Menu className='w-100'>
            <Dropdown.Item onClick={() => onBrandChange("visa")}>
              <FaCcVisa /> VISA
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onBrandChange("mastercard")}>
              <FaCcMastercard /> MasterCard
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onBrandChange("amex")}>
              <FaCcAmex /> AmEx
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='form-check mb-1'>
        <label
          className='form-check-label align-self-center'
          htmlFor='first_name'
        >
          <FaUser /> First Name
        </label>
        <input
          className='cc-input align-self-center'
          id='first_name'
          name='first_name'
          type='text'
          placeholder='John'
          value={newCard.first_name}
          onChange={onChange}
          required
        />
      </div>
      <div className='form-check mb-1'>
        <label
          className='form-check-label align-self-center'
          htmlFor='last_name'
        >
          <div className='icon-filler' /> Last Name
        </label>
        <input
          className='cc-input align-self-center'
          id='last_name'
          name='last_name'
          type='text'
          placeholder='Bovine'
          value={newCard.last_name}
          onChange={onChange}
          required
        />
      </div>

      <div className='form-check'>
        <label className='form-check-label align-self-center' htmlFor='number'>
          <FaCcVisa /> Number
        </label>
        <input
          className='cc-input align-self-center'
          id='cnumber'
          name='number'
          type='text'
          placeholder='**** **** **** 1234'
          value={newCard.number}
          onChange={onNumberChange}
          required
        />
      </div>

      <div className='form-check mb-1'>
        <label
          className='form-check-label align-self-center'
          htmlFor='exp_month'
        >
          <FaCalendar /> Date
        </label>
        <div className='cc-input form-check-input align-self-center input-sm d-inline-flex flex-row'>
          <Form.Control
            as='select'
            id='exp_month'
            name='exp_month'
            value={newCard.exp_month}
            onChange={onChange}
          >
            {[...Array(12).keys()].map((month) => (
              <option key={month + 1}>{month + 1}</option>
            ))}
          </Form.Control>
          <Form.Control
            as='select'
            id='exp_month'
            name='exp_year'
            value={newCard.exp_year}
            onChange={onChange}
          >
            {[...Array(20).keys()].map((year) => {
              year = currentDate.getFullYear() + year;
              return <option key={year}>{year}</option>;
            })}
          </Form.Control>
        </div>
      </div>

      <div className='form-check'>
        <label className='form-check-label align-self-center' htmlFor='cvv'>
          <FaLock /> CVV
        </label>
        <input
          className='cc-input form-check-input align-self-center input-sm'
          id='cvv'
          name='cvv'
          type='text'
          placeholder='___'
          value={newCard.cvv}
          onChange={onNumberChange}
          required
        />
      </div>

      {newCard.error ? (
        <div className='alert alert-danger w-100 mt-4' role='alert'>
          <AiOutlineClose /> <strong>{newCard.error}</strong>
        </div>
      ) : (
        <div className='alert alert-success w-100 mt-4' role='alert'>
          <FaCheck /> <strong>Valid</strong>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = (state) => ({
  newCard: state.newCard,
});

const mapDispatchToProps = {
  setNewCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCreditCardForm);
