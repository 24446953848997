import React, { useState } from "react";
import "./loadingButton.css";
import { Button } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";

const LoadingButton = ({ label, loading_label, onClick, variant }) => {

  const [isLoading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    onClick();
  };

  return (
    <Button
      className="m-1"
      variant={variant ? variant : "primary"}
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
    >
      {isLoading ? <FaSpinner className="icon-spin mr-2" /> : ''}
      {isLoading ? loading_label : label}
    </Button>
  );
};


export default LoadingButton;
