import React from "react";
import "./setCardOnFileModal.css";
import { Modal, Button } from "react-bootstrap";
import { updateCustomerCard, updateContactCard } from "../../api/terminalApi";
import { connect } from "react-redux";
import { LoadingButton } from "../../components";

const SetCardOnFileModal = ({ show, response, onClose, invoice, newCard }) => {
    const updateContact = () => {
        updateContactCard(invoice.token)
            .then(onClose());
    };

    const updateCustomer = () => {
        updateCustomerCard(invoice.token)
            .then(onClose());
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop='static'
            keyboard={false}
            animation={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Save Card Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Would you like to save this card information for future payments?
                <div className='w-100 p-2 mt-4 d-flex flex-column'>


                    {
                    /*
                    <LoadingButton
                        onClick={updateContact}
                        label="Update Contact Card On File"
                        loading_label='Updating Contact Card On File'
                    >
                    </LoadingButton>
                    */
                    }

                    <LoadingButton
                        onClick={updateCustomer}
                        label="Update Customer Card On File"
                        loading_label='Updating Customer Card On File'
                    >
                    </LoadingButton>

                    <Button variant='secondary' className='m-1' onClick={onClose}>
                        Don't Update Card On File
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    invoice: state.invoice,
    newCard: state.newCard,
});

export default connect(mapStateToProps)(SetCardOnFileModal);
