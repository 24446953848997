import React, { useEffect } from "react";
import "./creditCardOnFile.css";
import { FaCcVisa, FaCreditCard, FaUser, FaLock } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Dropdown } from "react-bootstrap";
import { setCvv } from "../../redux";
import { connect } from "react-redux";

const CreditCardOnFile = ({
  cvv,
  setCvv,
  card: { cc_type, cc_name, cc_last_4 },
}) => {
  useEffect(() => {
    setCvv("");
    //eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    const cvvNumber = e.target.value.replace(/\D/, "");
    setCvv(cvvNumber);
  };

  return (
    <form className='credit-card-mock form-group'>
      <div className='form-check'>
        <label className='form-check-label align-self-center' htmlFor='cc-visa'>
          <FaCreditCard /> Card Type
        </label>
        <Dropdown className='cc-input form-check-input d-inline-block align-self-center'>
          <Dropdown.Toggle
            size='sm'
            variant='outline-dark'
            id='brand'
            className='align-self-center w-100 text-uppercase'
            name='brand'
            disabled
          >
            {cc_type}
          </Dropdown.Toggle>
        </Dropdown>
      </div>
      <div className='form-check mb-1'>
        <label
          className='form-check-label align-self-center'
          htmlFor='cardholder'
        >
          <FaUser /> Cardholder
        </label>
        <input
          className='cc-input align-self-center'
          id='cardholder'
          type='text'
          defaultValue={cc_name}
          readOnly
        />
      </div>

      <div className='form-check'>
        <label className='form-check-label align-self-center' htmlFor='number'>
          <FaCcVisa /> Number
        </label>
        <input
          className='cc-input align-self-center'
          id='cnumber'
          type='text'
          defaultValue={"**** **** **** " + cc_last_4}
          readOnly
        />
      </div>

      <div className='form-check'>
        <label className='form-check-label align-self-center' htmlFor='cvv'>
          <FaLock /> CVV
        </label>
        <input
          className='cc-input form-check-input align-self-center input-sm'
          id='cvv'
          name='cvv'
          type='text'
          placeholder='___'
          value={cvv}
          onChange={onChange}
        />
      </div>

      <div className='alert alert-secondary w-100 mt-4' role='alert'>
        <AiOutlineExclamationCircle />{" "}
        <strong>You may enter a CVV before processing.</strong>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  cvv: state.cvv,
});

const mapDispatchToProps = {
  setCvv,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardOnFile);
