import callApi from "./callApi";

const setInvoiceToken = async (invoiceToken) => {
    sessionStorage.setItem("invoice_token", invoiceToken);
};

/**
 * Get Invoice.
 * Requires Invoice Token.
 */
export const getInvoice = async (invoiceToken) => {
    setInvoiceToken(invoiceToken);
    const res = await callApi(
        "get",
        `${process.env.REACT_APP_API_ENDPOINT}/terminal/v1/invoices`
    );
    if (res.error) return {error: res.error};
    else return res.invoice;
};

/**
 * Get Invoice.
 * Requires Invoice Token.
 */
export const closeInvoice = async (invoiceToken) => {
    setInvoiceToken(invoiceToken);
    const res = await callApi(
        "put",
        `${process.env.REACT_APP_API_ENDPOINT}/terminal/v1/invoices/close`
    );
    if (res.error) return {error: res.error};
    else return res.invoice;
};

/**
 * Get status list.
 * Requires Invoice Token.
 */
export const getStatusList = async (invoiceToken) => {
    setInvoiceToken(invoiceToken);
    const res = await callApi(
        "get",
        `${process.env.REACT_APP_API_ENDPOINT}/terminal/v1/invoices/statuses`
    );
    if (res.error) return {error: res.error};
    else return res.invoices;
};

/**
 * Get status list.
 * Requires Invoice Token.
 * @param {string} status - The new status (posted, opened, processed, failed, void)
 */
export const updateStatus = async (invoiceToken, status) => {
    setInvoiceToken(invoiceToken);
    const data = {status};
    const res = await callApi(
        "put",
        `${process.env.REACT_APP_API_ENDPOINT}/terminal/v1/invoices/statuses`,
        data
    );
    if (res.error) return {error: res.error};
    else return res.invoice;
};

/**
 * Gets the customer for an invoice.
 * Requires Invoice Token.
 */
export const getCustomer = async (invoiceToken) => {
    setInvoiceToken(invoiceToken);
    const res = await callApi(
        "get",
        `${process.env.REACT_APP_API_ENDPOINT}/terminal/v1/customers`
    );
    if (res.error) return {error: res.error};
    else return res.customer;
};

/**
 * Updates the current customer card on file fopr an invoice.
 * @param {string} invoiceToken - The invoice token.
 */
export const updateCustomerCard = async (invoiceToken) => {
    setInvoiceToken(invoiceToken);
    const data = {};
    const res = await callApi(
        "put",
        `${process.env.REACT_APP_API_ENDPOINT}/terminal/v1/customers`,
        data
    );
    if (res.error) return {error: res.error};
    else return res.customer;
};

/**
 * Gets the contact for an invoice.
 * Requires Invoice Token.
 */
export const getContact = async (invoiceToken) => {
    setInvoiceToken(invoiceToken);
    const res = await callApi(
        "get",
        `${process.env.REACT_APP_API_ENDPOINT}/terminal/v1/contacts`
    );
    if (res.error) return {error: res.error};
    else return res.contact;
};

/**
 * Updates the current contact card on file for an invoice.
 * @param {string} invoiceToken - The invoice token.
 */
export const updateContactCard = async (invoiceToken) => {
    setInvoiceToken(invoiceToken);
    const data = {};
    const res = await callApi(
        "put",
        `${process.env.REACT_APP_API_ENDPOINT}/terminal/v1/contacts`,
        data
    );
    if (res.error) return {error: res.error};
    else return res.contact;
};
