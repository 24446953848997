import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import "./App.css";
import { InvoiceDisplayScreen } from "./screens/";
import store from "./redux/store";
import { Provider } from "react-redux";

const App = () => {
  return (
    <Router history={history}>
      <Provider store={store}>
        <Switch>
          <Route exact path='/:invoiceToken' component={InvoiceDisplayScreen} />
          <Route exact path='*' component={InvoiceDisplayScreen} />
        </Switch>
      </Provider>
    </Router>
  );
};

export default App;
