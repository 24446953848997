import React, { Fragment, useState } from "react";
import "./processButton.css";
import { connect } from "react-redux";
import { payWithNewCard, payWithCardOnFile } from "../../api/convergeApi";
import { SetCardOnFileModal } from "..";
import { FaSpinner } from "react-icons/fa";

const ProcessButton = ({ invoice, fileChoice, newCard, cvv }) => {
    const [showModal, setShowModal] = useState(false);
    const [response, setResponse] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const onApproval = (res) => {
        console.log("Approved");
        if (fileChoice === "cc-new") {
            setResponse(res);
            setShowModal(true);
        } else window.location.href = invoice.redirect_success;
    };

    const onDeclined = (res) => {
        console.log("Declined");
        window.location.href = invoice.redirect_failure;
    };

    const onError = (res) => {
        console.error("Error");
        window.location.href = invoice.redirect_failure;
    };

    const process = () => {

        setLoading(true);

        // check form type: new card" or "card on file"
        if (fileChoice === "cc-new")
            payWithNewCard(invoice, newCard, onApproval, onDeclined, onError);
        else
            payWithCardOnFile(
                invoice,
                fileChoice,
                cvv,
                onApproval,
                onDeclined,
                onError
            );
    };

    return (
        <Fragment>
            <SetCardOnFileModal
                show={showModal}
                response={response}
                onClose={() => {
                    window.location.href = invoice.redirect_success;
                }}
            />
            <button
                className='btn btn-lg btn-success p-3 mx-auto w-50'
                type='button'
                disabled={isLoading}
                onClick={!isLoading ? process : null}
            >
                {isLoading ? <FaSpinner className="icon-spin mr-2" /> : ''}
                {isLoading ? "PROCESSING" : "PROCESS"}
            </button>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    cvv: state.cvv,
    newCard: state.newCard,
    invoice: state.invoice,
    fileChoice: state.fileChoice,
});

export default connect(mapStateToProps)(ProcessButton);
