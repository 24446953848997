import React from "react";
import "./voidButton.css";
import { connect } from "react-redux";
import { updateStatus, closeInvoice } from "../../api/terminalApi";
import { setInvoice } from "../../redux";
import history from "../../utils/history";

const VoidButton = ({ invoice, setInvoice }) => {
  const voidTransaction = () => {
    updateStatus(invoice.token, "void").then(() => {
      closeInvoice(invoice.token);
      history.push("/void");
      window.location.reload(false);
    });
  };

  return (
    <button
      className='btn btn-danger btn-sm pl-3 pr-3'
      onClick={voidTransaction}
    >
      Void
    </button>
  );
};

const mapStateToProps = (state) => ({
  invoice: state.invoice,
});

const mapDispatchToProps = {
  setInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoidButton);
