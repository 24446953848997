import React from "react";
import "./voidScreen.css";

const VoidScreen = () => {
  return (
    <div className='void-screen w-100 h-100 d-flex justify-content-center align-items-center'>
      <img
        src='https://volt.vet/images/volt-tile-logo.png'
        alt='logo'
        className='logo p-2 '
      />
      <div className='void-div bg-white w-50 h-50 p-4 d-flex flex-column justify-content-center align-items-center'>
        <h2>Invoice Voided</h2>
      </div>
    </div>
  );
};

export default VoidScreen;
