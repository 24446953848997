import React from "react";
import "./creditCardSelect.css";
import { FaCcVisa, FaCcMastercard, FaCreditCard } from "react-icons/fa";
import { NewCreditCardForm, CreditCardOnFile } from "..";
import { setFileChoice } from "../../redux";
import { connect } from "react-redux";

const CreditCardSelect = ({ invoice, fileChoice, setFileChoice }) => {
  if (!invoice) return null;
  const { contact, customer } = invoice;
  const customerCard = customer.cc_last_4 && {
    cc_type: customer.cc_type,
    cc_last_4: customer.cc_last_4,
    cc_name: customer.name,
  };
  const contactCard = contact.cc_last_4 && {
    cc_type: contact.cc_type,
    cc_last_4: contact.cc_last_4,
    cc_name: `${contact.first_name} ${contact.last_name}`,
  };
  return (
    <div className='card-select py-4 mx-auto d-flex flex-column justify-content-center align-items-start'>
      <form className='form-group'>
        <div className='form-check w-100'>
          <input
            className='form-check-input'
            id='cc-new'
            name='cc-on-file'
            type='radio'
            defaultChecked={true}
            onClick={() => setFileChoice("cc-new")}
          />
          <label className='form-check-label w-100' htmlFor='cc-new'>
            <FaCreditCard /> New Card
          </label>
        </div>
        {
          /*
          contactCard && (
            <div className='form-check'>
              <input
                className='form-check-input'
                id='on-file-contact'
                name='cc-on-file'
                type='radio'
                defaultValue='visa'
                onClick={() => setFileChoice("cc-contact")}
              />
              <label className='form-check-label w-100' htmlFor='on-file-contact'>
                <FaCcVisa /> Contact Card on File
              </label>
            </div>
          )
          */
        }
        {customerCard && (
          <div className='form-check'>
            <input
              className='form-check-input'
              id='on-file-customer'
              name='cc-on-file'
              type='radio'
              defaultValue='visa'
              onClick={() => setFileChoice("cc-customer")}
            />
            <label
              className='form-check-label w-100'
              htmlFor='on-file-customer'
            >
              <FaCcMastercard /> Customer Card on File
            </label>
          </div>
        )}
      </form>
      <hr />
      <div className='card-select-form'>
        {fileChoice === "cc-new" && <NewCreditCardForm />}
        {fileChoice === "cc-customer" && (
          <CreditCardOnFile card={customerCard} />
        )}
        {fileChoice === "cc-contact" && <CreditCardOnFile card={contactCard} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  fileChoice: state.fileChoice,
  cvv: state.cvv,
});

const mapDispatchToProps = {
  setFileChoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardSelect);
