import React from "react";
import moment from "moment";
import "./invoiceDisplay.css";
import { connect } from "react-redux";
import { VoidButton } from "..";

const InvoiceDisplay = ({ invoice }) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (!invoice) return null;
  const {
    external_id,
    customer,
    contact,
    date,
    note,
    charge,
    address,
  } = invoice;
  const {
    country,
    region,
    city,
    postal_code,
    address: streetAddress,
  } = address;
  return (
    <div className='panel-invoice px-5 pt-5 pb-1 m-auto mt-1 bg-white'>
      <h3>SSVS Transaction</h3>

      <table className='w-100 text-uppercase'>
        <tbody>
          <tr>
            <td>Invoice #</td>
            <td className='txnValue'>#{external_id}</td>
          </tr>
          <tr>
            <td>Customer</td>
            <td className='txnValue'>{customer.name}</td>
          </tr>

          <tr>
            <td>Date & Time</td>
            <td className='txnValue'>
              {moment(date).format("MMMM Do YYYY, h:mm a")}
            </td>
          </tr>
          <tr>
            <td>Operator</td>
            <td className='txnValue'>{`${contact.first_name} ${contact.last_name}`}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td className='txnValue'>
              {streetAddress}
              <br />
              {`${city}, ${region}, ${country}, ${postal_code}`}
            </td>
          </tr>
          <tr>
            <td>Memo</td>
            <td>
              <textarea readOnly>{note || null}</textarea>
            </td>
          </tr>
          <tr>
            <td>Charge</td>
            <td className='txnValue'>
              <span className='charge'>{currencyFormatter.format(charge)}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='row ml-1 mt-4 row-void d-flex flex-center'>
        <VoidButton />
        <span className='pl-3 align-self-center'>This transaction attempt</span>
      </div>
      <div className='invoice-uuid'>{invoice.id}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  invoice: state.invoice,
});

export default connect(mapStateToProps)(InvoiceDisplay);
