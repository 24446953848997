import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  InvoiceDisplay,
  CreditCardSelect,
  ProcessButton,
} from "../../components";
import "./invoiceDisplayScreen.css";
import { getInvoice } from "../../api/terminalApi";
import convergeLogo from "../../assets/img/converge.png";
import { setInvoice } from "../../redux";
import ErrorScreen from "../ErrorScreen/errorScreen";
import VoidScreen from "../VoidScreen";

const InvoiceDisplayScreen = ({ match, invoice, setInvoice }) => {
  useEffect(() => {
    if (match.params.invoiceToken && !invoice)
      getInvoice(match.params.invoiceToken).then((res) =>
        setInvoice({ ...res, token: match.params.invoiceToken })
      );
    //eslint-disable-next-line
  }, [match.params.invoiceToken]);

  if (match.params.invoiceToken === "void") return <VoidScreen />;
  if (!match.params.invoiceToken) return <ErrorScreen error='Missing Token' />;
  if (!invoice) return null;
  if (invoice && invoice.error) return <ErrorScreen error='Invalid Token' />;
  return (
    <div className='terminal h-100 d-flex'>
      <div className='panel-txn d-flex flex-column align-items-start'>
        <img
          src='https://volt.vet/images/volt-tile-logo.png'
          alt='logo'
          className='logo p-2 '
        />

        <InvoiceDisplay />
      </div>
      <div className='panel-pmnt d-flex flex-column'>
        <div className='m-auto'>
          <CreditCardSelect />
          <ProcessButton />
        </div>
      </div>
      <img
        src={convergeLogo}
        alt='converge'
        className='cp-logo ml-auto mr-auto mt-5'
      />
    </div>
  );
};

const matchStateToProps = (state) => ({
  invoice: state.invoice,
});

const matchDispatchToProps = {
  setInvoice,
};

export default connect(
  matchStateToProps,
  matchDispatchToProps
)(InvoiceDisplayScreen);
